import React from 'react'
import { Anchor, Divider } from 'antd'
import Container from '../components/Landing/Container'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'

const { Link } = Anchor

export default function DocumentationGuidePage({ setCookieValue, data }) {
    const affixContainerStyle: React.CSSProperties = isMobile
        ? {}
        : { display: 'grid', gridTemplateColumns: '1fr 6fr 1fr', marginLeft: 10, marginRight: 10 }

    return (
        <Container setCookieValue={setCookieValue} pathName="dokumentation">
            <SEO title="Dokumentation Guide" />
            <span style={affixContainerStyle}>
                {!isMobile && (
                    <Anchor offsetTop={100} targetOffset={156}>
                        <Link href="#guide-title" title="Bilagsindsamling">
                            <Link href="#step-1" title="1. Kalenderen" />
                            <Link href="#step-2" title="2. Kalender-menu" />
                            <Link href="#step-3" title="3. Bilagsoversigt" />
                            <Link href="#step-4" title="4. Overfør bilag" />
                            <Link href="#step-5" title="5. Overblik" />
                        </Link>
                    </Anchor>
                )}
                <div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 100,
                        }}
                        id="guide-title"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            Indsæt dine bilag
                        </h1>
                        <p className="landing-guide-header-p">
                            Overfør dine bilag til ll33a.dk, det er nemt at holde overblik over dine
                            bilag ved at overføre bilag for hver dag i kalenderen. Bilagene
                            opbevares sikkert på vores servere så du altid har adgang til dem fra
                            alle dine devices.
                        </p>
                    </div>
                    <Divider />
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-1"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>1. Gå til kalender-siden</h1>
                            <p style={{ maxWidth: 400 }}>
                                Tryk en dag i kalenderen for at overføre et bilag for den dato.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 1"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s1.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-2"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>2. Åbn kalender-menuen</h1>
                            <p style={{ maxWidth: 400 }}>
                                Denne menu åbnes og herfra kan du trykke videre på bilagsoversigten.
                                Du kan også tilføje en note til datoen.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 2"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s2.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-3"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>3. Bilagsoversigten</h1>
                            <p style={{ maxWidth: 400 }}>
                                Herfra kan du se hvilke bilag du har overført til denne dato. I
                                eksemplet er der ingen bilag endnu for d. 18 januar 2017. Tryk på
                                '+' og overfør en fil.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 3"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s3.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-4"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>4. Overfør bilag</h1>
                            <p style={{ maxWidth: 400 }}>
                                Filer du har overført vil stå her ved siden af '+'-knappen. Du kan
                                preview, slette og markere filen 'included' eller 'excluded' fra
                                skatterapporter. Det sidste betyder at du kan overføre kladder som
                                du ikke nødvendigvis vil sende til skattestyrelsen, men stadig gerne
                                vil have tilknyttet dagen.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 4"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s4.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-5"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>5. Overblikket</h1>
                            <p style={{ maxWidth: 400 }}>
                                Når du har overført et bilag vises der en grøn prik ud for datoen.
                                Noter du tilføjer vil også vises som en prik. Du kan skifte farven
                                på noterne for at markere forskellige datoer.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 5"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s5.childImageSharp.fluid}
                        />
                    </div>
                </div>
            </span>
        </Container>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "guide-assets/bilag-step-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s2: file(relativePath: { eq: "guide-assets/bilag-step-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s3: file(relativePath: { eq: "guide-assets/bilag-step-3.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s4: file(relativePath: { eq: "guide-assets/bilag-step-4.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s5: file(relativePath: { eq: "guide-assets/bilag-step-5.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`
